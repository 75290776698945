import React, { useEffect } from "react";
import "./App.css";
import BaseLayout from "./layout/BaseLayout";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Home from "./pages/Home";

import { Symfoni } from "./hardhat/SymfoniContext";
import Viewer from "./pages/Viewer";

function App() {
  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.removeAllListeners();
      window.ethereum.on("chainChanged", () => {
        document.location.reload();
      });
    }
  });


  function renderApp() {
    return (
      <>
        <Switch>
          <Route exact path="/mytigers">
          <Symfoni autoInit={true} showLoading={false}>
            <BaseLayout>
              <Viewer mint={() => console.log("mi click")} />
            </BaseLayout>
            </Symfoni>
            </Route>

            <Route exact path="*">

            <Symfoni autoInit={true} showLoading={false}>
            <BaseLayout>
            <Home mint={() => console.log("mi click")} />
                </BaseLayout>
            </Symfoni>
          </Route>
        </Switch>
      </>
    );
  }

  return <BrowserRouter>{renderApp()}</BrowserRouter>;
}

export default App;
