import Header from "../layout/Header";
import Twitter from "../assets/socials/twitter.svg";
// import Discord from "../assets/socials/discord.svg";
import Logo from "../assets/logo.png";

function BaseLayout({ children }) {
  return (
    <div
      style={{ fontFamily: "Montserrat" }}
      className="flex text-white bg-dark h-full flex-col items-center w-full"
    >
      <Header />
      <main
        style={{}}
        className="text-white border-bottom border-white z-10 w-full"
      >
        {children}
      </main>
      <footer
        style={{ height: 80 }}
        className="bottom-0 py-10 fixed px-5 w-full bg-dark border-t border-white flex justify-center items-center text-purple text-2xs right-0 left-0 "
      >
        <div className="flex items-center justify-between max-w-7xl w-full">
          <img className="cursor-pointer w-80" alt="preview" src={Logo} />
          <div className="flex  z-10 ">
            <a
              className="bg-gradient-to-r from-blue to-blue mx-2 text-black p-1.5 rounded-full"
              target="_blank"
              href="https://twitter.com/funkytigermob"
              rel="noreferrer"
            >
              <img className="w-5" alt="twitter" src={Twitter} />
            </a>
            {/* <a
              className="bg-gradient-to-r from-blue to-green  text-black p-1.5 rounded-full"
              target="_blank"
              href="https://discord.gg/wtJEjJ7ber"
              rel="noreferrer"
            >
              <img className="w-5" alt="discord" src={Discord} />
            </a> */}
          </div>
        </div>
      </footer>
    </div>
  );
}

export default BaseLayout;
