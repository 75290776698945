export const chains = {
  Fantom: {
    chainId: "0xfa",
    chainName: "Fantom Testnet",
    nativeCurrency: {
      name: "Fantom",
      symbol: "FTM",
      decimals: 18,
    },
    rpcUrls: ["https://rpc.ftm.tools/"],
    blockExplorerUrls: ["https://ftmscan.com/"],
  },
  FantomTest: {
    chainId: "0xfa2",
    chainName: "Fantom Testnet",
    nativeCurrency: {
      name: "Fantom",
      symbol: "FTM",
      decimals: 18,
    },
    rpcUrls: ["https://rpc.testnet.fantom.network/"],
  },
  Binance: {
    chainId: "0x38", // A 0x-prefixed hexadecimal chainId
    chainName: "Smart Chain",
    nativeCurrency: {
      name: "Binance",
      symbol: "BNB",
      decimals: 18,
    },
    rpcUrls: ["https://bsc-dataseed.binance.org"],
    blockExplorerUrls: ["https://bscscan.com"],
  },
  BinanceTest: {
    chainId: "0x61", // A 0x-prefixed hexadecimal chainId
    chainName: "Smart Chain - Testnet",
    nativeCurrency: {
      name: "Binance",
      symbol: "BNB",
      decimals: 18,
    },
    rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545"],
    blockExplorerUrls: [" https://testnet.bscscan.com"],
  },
  Harmony: {
    chainId: "0x63564C40",
    chainName: "Harmony Mainnet",
    nativeCurrency: {
      name: "Harmony One",
      symbol: "ONE",
      decimals: 18,
    },
    rpcUrls: ["https://api.harmony.one"],
    blockExplorerUrls: ["https://explorer.harmony.one"],
  },
  HarmonyTest: {
    chainId: "0x6357D2E0", // A 0x-prefixed hexadecimal chainId
    chainName: "Harmony Testnet",
    nativeCurrency: {
      name: "Harmony One",
      symbol: "ONE",
      decimals: 18,
    },
    rpcUrls: ["https://api.s0.b.hmny.io"],
    blockExplorerUrls: ["https://explorer.pops.one"],
  },
  Avax: {
    chainId: "0xA86A", // A 0x-prefixed hexadecimal chainId
    chainName: "Avalanche Network",
    nativeCurrency: {
      name: "AVAX",
      symbol: "AVAX",
      decimals: 18,
    },
    rpcUrls: ["https://api.avax.network/ext/bc/C/rpc"],
    blockExplorerUrls: ["https://cchain.explorer.avax.network/"],
  },
};
