import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import Logo from "../assets/logo.png";
import x from "../assets/components/x.svg";
import cs from "classnames";
import { useEVM } from "../hooks/EVMhook";
// import Wallet from "../assets/components/wallet.svg";
import Hamburger from "../components/Hamburger";
import { ethers } from "ethers";

function Header() {
  const history = useHistory();
  const { isMetamask, addNetwork, isConnected, isNetwork, address, mintService } = useEVM();
  const [rewards, setRewards] = useState(0);

  useEffect(() => {
    mintService?.instance
      ?.getReflectionBalances()
      .then((r) => setRewards(ethers.utils.formatEther(r).substring(0, 7)));
  });

  const [show, setShow] = useState(false);

  function getButton() {
    if (!isMetamask) return "Install Metamask";
    if (isConnected && isNetwork) return address?.substring(0, 9);
    else if (isNetwork && !isConnected) return "Connect Wallet";
    else return "Switch to Fantom";
  }

  function claim() {
    mintService?.instance?.claimRewards();
  }

  return (
    <>
      {show && (
        <div
          className={cs(
            "z-50 bg-dark transition-colors fixed duration-500 top-0 bottom-0 flex flex-col left-0 right-0"
          )}
        >
          <button
            onClick={() => setShow(false)}
            className="outline-none z-50 self-end justify-self-end pr-8 pt-8 text-xl"
          >
            <img alt="x" className="w-6 cursor-pointer " src={x} />
          </button>
          <div className="flex pt-20 -mt-10 flex-col gap-10 justify-start h-full items-center">
            <img
              onClick={() => {
                history.push("/");
                setShow(false);
              }}
              alt="logo"
              className="cursor-pointer w-96"
              src={Logo}
            />
            {/* <span
              onClick={() => {
                history.push("/mint");
                setShow(false);
              }}
              className="font-normal text-darker-low mb-6 cursor-pointer"
            >
              Mint!
            </span> */}
            <div
              onClick={() => {
                return isMetamask
                  ? addNetwork()
                  : window.open(
                      "https://docs.fantom.foundation/tutorials/set-up-metamask",
                      "_blank"
                    );
              }}
              className="font-normal items-center flex rounded px-2 py-1  cursor-pointer"
            >
              <div className="w-4 mt-2"></div>
              {getButton()}
            </div>
            <span
              onClick={() => {
                history.push("/#mint");
                setShow(false);
              }}
              className=" font-normal cursor-pointer"
            >
              Mint
            </span>
            <span
              onClick={() => {
                history.push("/mytigers");
                setShow(false);
              }}
              className="mt-1  font-normal cursor-pointer"
            >
              My Tigers
            </span>
            <div className="flex flex-col gap-3 text-white font-semibold items-center">
              <span>Rewards: {parseFloat(rewards).toFixed(3)}</span>
              <button
                onClick={claim}
                className="px-3 rounded-full bg-white text-dark "
              >
                Claim My Funk
              </button>
            </div>
          </div>
        </div>
      )}
      <header
        className={cs(
          `h-20 w-full px-5 bg-dark transition-colors duration-1000  flex  z-40  `
        )}
      >
        <nav className="font-semibold h-16 max-w-7xl text-white flex items-center container w-full justify-between mx-auto">
          <img
            onClick={() => {
              history.push("/");
              setShow(false);
            }}
            alt="logo"
            className="cursor-pointer w-80"
            src={Logo}
          />
          <Hamburger onClick={() => setShow(true)} />
          <div className="md:flex hidden items-center">
          <div className="flex mr-6 gap-3 text-white font-semibold items-center">
              <span>Rewards: {parseFloat(rewards).toFixed(3)}</span>
              <button
                onClick={claim}
                className="px-3 rounded-full bg-white text-dark "
              >
                Claim My Funk
              </button>
            </div>
            <span
              onClick={() => {
                history.push("/#mint");
                setShow(false);
              }}
              className="mr-6  font-normal cursor-pointer"
            >
              Mint
            </span>
            <span
              onClick={() => {
                history.push("/mytigers");
                setShow(false);
              }}
              className="mr-1  font-normal cursor-pointer"
            >
              My Tigers
            </span>
            <div
              onClick={() => {
                return isMetamask
                  ? addNetwork()
                  : window.open(
                      "https://docs.fantom.foundation/tutorials/set-up-metamask",
                      "_blank"
                    );
              }}
              className="font-normal items-center flex rounded px-2 py-1 cursor-pointer"
            >
              <div className="w-4 mr-2"></div>
              {getButton()}
            </div>
          </div>
        </nav>
      </header>
    </>
  );
}

export default Header;
