import cs from "classnames";
import banana from "../assets/twitterlogo.png";
import rarities from "./rarities.json";
import { useEVM } from "../hooks/EVMhook";

import { useState } from "react";

function AssetCard({ data, index, useName = false, isModal, placeholder }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [loaded, setLoaded] = useState(false);
  data = { ...data };
  const { mintService, address } = useEVM();
  const [wallet, setWallet] = useState("");

  async function handleSend() {
    mintService?.instance.transferFrom(address, wallet, data.id);
  }


  function renderMeta(meta) {
    const metaList = [];
    let i = 0;
    for (const attribute of meta) {
      metaList.push(
        <div className={cs("flex gap-2 flex-wrap items-center")} key={i}>
          <span className="text-yellow font-bold  font-semibold">
            {attribute.trait_type}:{" "}
          </span>
          <span className="text-sm ">{attribute.value}</span>-
          <span className="text-sm ">
            
            {(attribute.trait_type !== "Tier" &&

              rarities[attribute.trait_type].find(
                (t) => t[0] === attribute.value
              )[1]
            /5555*100).toFixed(2)+"%"}
          </span>
        </div>
      );
      i++;
    }
    return metaList.reverse();
  }

  return (
    <div
      onClick={() => (!isModal && !modalOpen ? setModalOpen(true) : null)}
      style={{minHeight: placeholder ? "" : 475}}
      className={cs("rounded-xl bg-white flex flex-col", {
        "shadow-md p-5 cursor-pointer hover:shadow-2xl": !isModal,
      })}
    >

      {!loaded && (
        <img
          style={{ maxHeight: "20rem" }}
          alt="preview"
          className={cs("animate-pulse w-full max-w-sm ")}
          src={banana}
        />
      )}

      <img
        onLoad={() => {
          setLoaded(true);
        }}
        style={{ maxHeight: "20rem", height: "15rem", width: "15rem" }}
        alt="preview"
        className={cs("max-w-sm w-full ", {
          hidden: !loaded,
        })}
        src={data.image}
      />

      <div className="flex text-black w-full bg-white rounded-b-lg flex-col">
        <div className="flex flex-col text-sm">
          <span
            className="text-black text-center w-full my-3 text-lg font-semibold"
          >
            {data.id !== null && <>Funky Tiger #{data?.id}</>}
          </span>
          {renderMeta(data?.attributes ||[])}
          <div className="w-full gap-1 mt-4 flex">
              <input
                onChange={(e) => setWallet(e.target.value)}
                placeholder="Enter wallet to send"
                className="outline-none text-yellow rounded pl-3 w-full  bg-dark bg-opacity-20"
              />
              <button
                onClick={handleSend}
                className="border rounded border-blue-900 text-blue-900 p-2"
              >
                Send
              </button>
            </div>
        </div>
      </div>
    </div>
  );
}

export default AssetCard;
