import { useState } from "react";

function CountInput({ onChange, max = 20 }) {
  const [count, setCount] = useState(1);

  function handleCountState(c) {
    if (c === "") return setCount("");
    if (!parseInt(c)) return;

    if (c < 1 || c > 50) return;
    else setCount(parseInt(c));
    onChange(parseInt(c));
  }

  return (
    <div className="flex">
      <div
        style={{ fontFamily: "Montserrat" }}
        className="flex py-2.5 text-white w-40 mr-4 border bg-dark border-dark rounded px-4 items-center"
      >
        <button
          onClick={() => handleCountState(count - 1)}
          className="text-2xl pb-0.5 font-semibold"
        >
          {"-"}
        </button>
        <input
          value={count}
          onChange={(e) => handleCountState(e.target.value)}
          className="text-2xl text-center bg-transparent text-white font-semibold outline-none focus:outline-none w-full h-full"
        />
        <button
          onClick={() => handleCountState(count + 1)}
          className="text-2xl outline-none font-semibold"
        >
          {"+"}
        </button>
        <button
          onClick={() => handleCountState(25)}
          className="text-2xl ml-3 outline-none font-semibold"
        >
          {"MAX"}
        </button>
      </div>
    </div>
  );
}

export default CountInput;
