import { useState, useEffect, useRef } from "react";
import { fetchData } from "../lambda/lambda";
import collage from "../assets/collage.png";
import og from "../assets/OGRoyalty.png";
import reflect from "../assets/ReflectionTiger.png";
import Rights from "../assets/CommercialRights.png";
import main from "../assets/main.png";
import woods from "../assets/Woods.png";
import portal from "../assets/Portal.png";
import space from "../assets/Space.png";
import Countdown from "react-countdown";
import CountInput from "./Mint/CountInput";
import { useEVM } from "../hooks/EVMhook";
import AssetCard from "../components/AssetCard";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import "./Home.css";

const renderer = ({ days, hours, minutes, seconds, completed }) => {
  // Render a countdown
  return (
    <div
      style={{ fontFamily: "Montserrat" }}
      className="text-white self-center mb-16 text-4xl"
    >
      Time Till Funk - {days}d:{hours}h:{minutes}m:{seconds}s
    </div>
  );
};

const countURL =
  "https://xc9sul6ysd.execute-api.us-east-1.amazonaws.com/totalSupply";
const url =
  "https://xc9sul6ysd.execute-api.us-east-1.amazonaws.com/listmetadata?indices=";

function Home() {
  const [mintedCount, setMintedCount] = useState(1);
  const ref = useRef([]);
  const [count, setCount] = useState(1);
  const [mintID, setMintID] = useState(null);
  const [mintedIds, setMintedIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lockedCount, setLockedCount] = useState(0);
  const [mintedUrls, setMintedUrls] = useState([]);
  const { mintService, aUmanService, bUmanService, address } = useEVM();
  const [error, setError] = useState(null);

  useEffect(() => {
    mintService.instance?.removeListener("Transfer");
    mintService.instance?.on("Transfer", async (a, b, c, d) => {
      const harmonautIndex = c.toNumber();
      if (loading && mintID === d.transactionHash) {
        ref.current = [...ref.current, harmonautIndex.toString()];
        setMintedIds(ref.current);
      }
    });
    if (mintedIds.length === lockedCount && mintedIds.length !== 0) {
      fetchData(url + mintedIds.join(",")).then((_data) => {
        setMintedUrls(_data);
        setLoading(false);
      });
    }

    fetchData(countURL).then((d) => setMintedCount(d?.data));
    // fetchData(countURL).then((d) => setMintedCount(0));

}, [loading, lockedCount, mintID, mintService.instance, mintedIds]);

  async function handleMint() {
    setError(null);
    try {
      ref.current = [];
      setMintedIds([]);
      setLockedCount(count);
      const price = await mintService.instance?.price();
      const aumanBalance = await aUmanService?.instance?.balanceOf(address)
      const bUmanBalance = await bUmanService?.instance?.balanceOf(address)
      let mulPrice = price.mul(count);
      if(aumanBalance.toNumber() > 0 || bUmanBalance.toNumber() > 0) {
        mulPrice = mulPrice.mul(85).div(100);
      }
      const transaction = await mintService.instance?.mint(count, {
        value: mulPrice,
      });

      setMintedUrls(
        new Array(count).fill(1).map((i, index) => ({ _id: i + index * 1234 }))
      );
      setLoading(true);
      setMintID(transaction.hash);
    } catch (e) {
      setError(e.data.message);
    }
  }

  return (
    <div className="w-full text-black   flex flex-col items-center justify-center">
      <div
        style={{ backgroundImage: `url(${main})`, backgroundSize: "cover" }}
        className="flex justify-center w-full "
      >
        <div className="w-full flex justify-center bg-opacity-60 bg-dark ">
          <div className="max-w-7xl px-5 flex flex-col w-full">
            <span className=" italic text-white  mt-20 font-bold text-5xl md:text-5xl max-w-sm md:max-w-xl">
              BRINGING THE FUNK TO THE FANTOM NFT COMMUNITY
            </span>
            <div id="mint" className="flex mt-10 self-center gap-3 flex-col">
              <span className="text-white font-medium text-center text-2xl">{mintedCount}/2,500 Funky Tigers minted</span>
              <div className="flex self-center h-14">
                <CountInput onChange={(c) => setCount(c)} />
                <button
                  style={{ fontFamily: "Montserrat" }}
                  onClick={handleMint}
                  className="bg-dark font-semibold my-10 text-2xl h-14 px-4 md:self-center text-white  rounded"
                >
                  MINT!
                </button>
                
              </div>
              {error && (
                <span className="p-4 bg-white bg-opacity-30">{error}</span>
              )}
              {mintID && mintedUrls.length > 0 && (
                <div className="flex max-w-6xl flex-col w-full mt-16">
                  <span
                    style={{ fontFamily: "Montserrat" }}
                    className="self-center mb-8 text-white font-semibold text-3xl md:text-4xl"
                  >
                    My Funky Tiger Mob
                  </span>
                  <div className="flex flex-wrap gap-3 mt-3 w-full justify-center">
                    {!loading &&
                      mintedUrls.map((u, index) => (
                        <div key={u.id} className="w-72">
                          <AssetCard data={u} index={index} />
                        </div>
                      ))}
                    {loading &&
                      mintedUrls.map((u, index) => (
                        <div key={u._id} className="w-72">
                          <AssetCard data={u} index={index} placeholder={true} />
                        </div>
                      ))}
                  </div>
                </div>
              )}
            </div>
            <span className="my-10 text-2xl font-medium text-white">
              Funky Tigers are a mob of 5,555 randomly generated tigers roaming
              around the Fantom blockchain. Each tiger brings their own unique
              funk to Fantom with a variety of different faces, hats, and
              clothes.
            </span>

            <Countdown
              renderer={renderer}
              date={new Date("2021-10-22T20:00:00Z")}
            />

            <img alt="preview" className=" self-end" src={collage} />
          </div>
        </div>
      </div>
      <div className="w-full flex justify-center bg-dark ">
        <div className="mt-20 pb-20 px-5 max-w-7xl bg-dark flex flex-col w-full">
          <div className=" flex text-white flex-col text-4xl md:text-5xl font-extrabold">
            FUNKY PERKS FOR FUNKY TIGERS
          </div>
          <div className="flex  w-full justify-center md:justify-start mt-10 flex-wrap  gap-8">
            <div
              style={{
                backgroundImage: `url(${space})`,
                backgroundSize: "cover",
              }}
              className="flex w-full rounded-lg max-w-sm"
            >
              <div className="px-4 pt-8 rounded-lg  hover:shadow-2xl bg-dark-low bg-opacity-60 flex items-between flex-col">
                <div className="flex flex-col">
                  <span className="text-2xl h-12 font-bold text-white">
                    REFLECTIVE MINTING
                  </span>
                  <span className="text-lg mt-5 text-white">
                    For every mint in the collection,{" "}
                    <span className="font-bold text-white text-2xl">10%</span>{" "}
                    of every minting fee gets reflected to previous Funky Tiger
                    holders equally, giving an advantage to early minters!
                    <br />
                  </span>
                </div>
                <img alt="preview" className="w-60 self-center" src={reflect} />
              </div>
            </div>
            <div className="w-full rounded-lg max-w-sm hover:shadow-2xl bg-dark-low flex flex-col">
              <div
                style={{
                  backgroundImage: `url(${portal})`,
                  backgroundSize: "cover",
                }}
                className="flex w-full  rounded-lg max-w-sm"
              >
                <div className=" px-4 pt-8  rounded-lg flex-1 max-w-sm h-full bg-opacity-60 justify-between bg-dark-low flex flex-col">
                  <div className="flex flex-col">
                    <span className="text-2xl h-12 font-bold text-white">
                      HOLDER ROYALTIES
                    </span>
                    <span className="text-lg mt-5 text-white">
                      Funky Tiger holders collect a{" "}
                      <span className="font-bold text-white text-2xl">3%</span>{" "}
                      royalty of all tiger sales. Nothing else is required, just
                      hold and claim on our site!
                      <br />
                    </span>
                  </div>
                  <img alt="preview" className="w-60 self-center" src={og} />
                </div>
              </div>
            </div>
            <div className=" w-full rounded-lg max-w-sm hover:shadow-2xl bg-dark-low flex flex-col">
              <div
                style={{
                  backgroundImage: `url(${woods})`,
                  backgroundSize: "cover",
                }}
                className="flex w-full h-full justify-between flex-col rounded-lg max-w-sm"
              >
                <div className="px-4 pt-8 rounded-lg flex-1 max-w-sm h-full justify-between bg-opacity-60 bg-dark-low flex flex-col">
                  <div className="flex flex-col">
                    <span className="text-2xl h-12 font-bold text-white">
                      FULL COMMERCIAL RIGHTS
                    </span>
                    <span className="text-lg mt-5 text-white">
                      You mint it, you own it! You are free to do anything with
                      Funky Tigers you own under a non-exclusive license.
                      <br />
                    </span>
                  </div>
                  <img
                    alt="preview"
                    className="w-60 justify-self-end self-center"
                    src={Rights}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{ backgroundImage: `url(${main})`, backgroundSize: "cover" }}
        className="flex justify-center w-full "
      >
        <div className="w-full px-5 bg-dark pb-20 bg-opacity-60 flex justify-center ">
          <div className="max-w-7xl flex flex-col w-full">
            <span className="mb-10 text-white self-center mt-20 font-bold text-4xl md:text-5xl max-w-sm md:max-w-xl">
              ROADMAP
            </span>
            <VerticalTimeline
              lineColor="black"
              className="vertical-timeline-custom-line"
            >
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{
                  background: "#222431",
                  color: "#fff",
                  boxShadow: "0 3px 0 #2C2E3F",
                }}
                contentArrowStyle={{
                  borderRight: "7px solid  #222431",
                }}
                iconStyle={{
                  background: "rgba(44, 46, 63, 0.5)",
                  color: "#33364A",
                  width: 50,
                  height: 50,
                }}
                icon={
                  <span className="flex justify-center text-white text-lg font-bold h-full items-center">
                    0%
                  </span>
                }
              >
                <h3 className="font-bold text-2xl">TIME TO FUNK</h3>
                <p className="text-lg">
                  Minting goes live! You will be able to mint FTM (Funky Tiger
                  Mob) on the best blockchain network!
                </p>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{
                  background: "#222431",
                  color: "#fff",
                  boxShadow: "0 3px 0 #2C2E3F",
                }}
                contentArrowStyle={{
                  borderRight: "7px solid  #222431",
                }}
                iconStyle={{
                  background: "rgba(44, 46, 63, 0.5)",
                  color: "#33364A",
                  width: 50,
                  height: 50,
                }}
                icon={
                  <span className="flex text-white justify-center text-lg font-bold h-full items-center">
                    25%
                  </span>
                }
              >
                <h3 className="font-bold text-2xl">FUNKY TIGER GIVE AWAY</h3>
                <p className="text-lg">
                  We will give 3 online Fantom Developer Conference tickets to 3
                  lucky Funky Tiger owners.
                </p>
                <p className="text-lg">
                  We will be giving away 10 Funky Tigers to our community
                  members!
                </p>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{
                  background: "#222431",
                  color: "#fff",
                  boxShadow: "0 3px 0 #2C2E3F",
                }}
                contentArrowStyle={{
                  borderRight: "7px solid  #222431",
                }}
                iconStyle={{
                  background: "rgba(44, 46, 63, 0.5)",
                  color: "#33364A",
                  width: 50,
                  height: 50,
                }}
                icon={
                  <span className="flex text-white justify-center text-lg font-bold h-full items-center">
                    50%
                  </span>
                }
              >
                <h3 className="font-bold text-2xl">COMMUNITY FANTOM RAFFLE</h3>
                <p className="text-lg">
                  We will be doing a raffle for our community members for a
                  chance to win Fantom!
                </p>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{
                  background: "#222431",
                  color: "#fff",
                  boxShadow: "0 3px 0 #2C2E3F",
                }}
                contentArrowStyle={{
                  borderRight: "7px solid  #33364A",
                }}
                iconStyle={{
                  background: "rgba(44, 46, 63, 0.5)",
                  color: "#33364A",
                  width: 50,
                  height: 50,
                }}
                icon={
                  <span className="flex text-white justify-center text-lg font-bold h-full items-center">
                    75%
                  </span>
                }
              >
                <h3 className="font-bold text-2xl">FUNKY TIGER GIVE AWAY 2</h3>
                <p className="text-lg">
                  We will also be giving away another 10 Funky Tigers to our
                  community members!
                </p>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{
                  background: "#222431",
                  color: "#fff",
                  boxShadow: "0 3px 0 #2C2E3F",
                }}
                contentArrowStyle={{
                  borderRight: "7px solid  #222431",
                }}
                iconStyle={{
                  background: "rgba(44, 46, 63, 0.5)",
                  color: "#33364A",
                  width: 50,
                  height: 50,
                }}
                icon={
                  <span className="flex p-2 text-white justify-center text-lg font-bold h-full items-center">
                    100%
                  </span>
                }
              >
                <h3 className="font-bold text-2xl">Merch Store</h3>
                <p className="text-lg">
                  Lets rep the Funky Tiger Mob! We will also raffle some goodies
                  to our community members!
                </p>
              </VerticalTimelineElement>
            </VerticalTimeline>
          </div>
        </div>
      </div>
      <div className="w-full pb-20 bg-dark flex justify-center ">
        <div className="max-w-7xl px-5 bg-dark flex flex-col w-full">
          <span className="mb-10 text-white self-start mt-20 font-bold text-4xl md:text-5xl max-w-sm md:max-w-xl">
            FAQ
          </span>

          <span className="text-3xl font-semibold text-white mt-10">
            How much does it cost to mint a Funky Tiger?
          </span>
          <span className="text-white text-2xl italic mt-5">
            20 FTM (10% reflected back to holders)
          </span>
          <span className="text-3xl font-semibold text-white mt-10">
            What can I do with my Funky Tiger?
          </span>
          <span className="text-white text-2xl italic mt-5">
            You are free to do anything with your Funky Tiger under a
            non-exclusive license.
          </span>
          <span className="text-3xl font-semibold text-white mt-10">
            When can I mint a Funky Tiger?
          </span>
          <span className="text-white text-2xl italic mt-5">
            Mint day will be on Friday Oct 22nd @ 4:00pm EST!!
          </span>
          <span className="text-3xl font-semibold text-white mt-10">
            How do I mint?
          </span>
          <span className="text-white text-2xl italic mt-5">
            To mint you must use metamask on our site on the Fantom Blockchain
          </span>
          <span className="text-3xl font-semibold text-white mt-10">
            How to set up metamask on Fantom
          </span>
          <a
            href="https://docs.fantom.foundation/tutorials/set-up-metamask"
            className="text-white text-2xl italic mt-5"
          >
            Click here for instructions
          </a>
        </div>
      </div>
    </div>
  );
}

export default Home;
