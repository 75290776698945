import { useEffect, useState } from "react";
import AssetCard from "../components/AssetCard";
import { useEVM } from "../hooks/EVMhook";
import { fetchData } from "../lambda/lambda";
import ReactPaginate from "react-paginate";
import "./paginateStyles.css";

const url =
  "https://xc9sul6ysd.execute-api.us-east-1.amazonaws.com/listmetadata?indices=";

function Viewer() {
  const [assets, setAssets] = useState([]);
  const [loading, setLoading] = useState(false);
  const { mintService, address } = useEVM();
  const [totalAssets, setTotalAssets] = useState(0);

  async function fetchAssets(_page) {
    setLoading(true);
    try {
      if (!address) return;

      const ownerCount = await mintService.instance?.balanceOf(address);

      const ownerCountInt = ownerCount.toNumber();
      let _cc = 0;
      if (ownerCountInt > 0) {
        const _assetids = [];
        const range = [...Array(ownerCountInt).keys()].map(
          (k) => k + _page * 20
        );
        for (const i of range) {
          if (_cc >= 20 || i > ownerCount.toNumber() - 1) {
            break;
          }
          const assetId = await mintService.instance?.tokenOfOwnerByIndex(
            address,
            i
          );
          _assetids.push(assetId);
          _cc++;
        }

        const _assetMeta = await fetchData(
          url + _assetids.map((id) => id.toString()).join(",")
        );

        setAssets(_assetMeta);
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  }
  useEffect(() => {
    async function fetchAssets(_page) {
      setLoading(true);
      try {
        const ownerCount = await mintService?.instance?.balanceOf(address);
        const ownerCountInt = ownerCount.toNumber();
        let _cc = 0;
        if (ownerCountInt > 0) {
          const _assetids = [];
          const range = [...Array(ownerCountInt).keys()].map(
            (k) => k + (_page - 1) * 20
          );
          for (const i of range) {
            if (_cc >= 20) {
              break;
            }

            const assetId = await mintService?.instance?.tokenOfOwnerByIndex(
              address,
              i
            );
            _cc++;
            _assetids.push(assetId);
          }

          const _assetMeta = await fetchData(
            url + _assetids.map((id) => id.toString()).join(",")
          );

          setAssets(_assetMeta);
        }
        setLoading(false);
      } catch (e) {
        console.log(e);
        setLoading(false);
      }
    }
    if (mintService?.instance && address) {
      fetchAssets(1);
      mintService.instance?.balanceOf(address).then((ownerCount) => {
        const ownerCountInt = ownerCount.toNumber();
        setTotalAssets(ownerCountInt);
      });
    }
  }, [address, mintService.instance]);

  return (
    <div className="w-full h-full bg-dark pb-20 pt-8 px-5 text-xl flex-col md:px-0 flex flex-wrap items-center justify-start">
      <div className="flex md:flex-row flex-col justify-between w-full px-6 max-w-7xl">
        <div className="flex w-full flex-col">
          <span
            className="text-2xl self-center text-white font-bold"
          >
            My Funky Tiger Mob
          </span>

        </div>
      </div>{" "}
      <div className="w-full mt-6 justify-center max-w-6xl flex">
        <div className="self-center px-6 max-w-pg h-10">
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            initialPage={0}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={Math.ceil(totalAssets / 20)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={({ selected }) => fetchAssets(selected)}
            containerClassName={"pagination"}
            activeClassName={"active"}
          />
        </div>
      </div>
      {loading && (
        <span
          className="text-white w-full text-center text-xl mt-8 animate-pulse"
        >
          Getting your Tigers!...
        </span>
      )}
      {!loading && (
        <div className="flex max-w-6xl mt-8 flex-1 gap-7 justify-center flex-wrap">
          {assets
            ?.sort(function (a, b) {
              return a.id - b.id;
            })
            .map((u, index) => (
              <div className="w-72 ">
                <AssetCard data={u} useName={true} index={index} key={index} />
              </div>
            ))}
        </div>
      )}
    </div>
  );
}

export default Viewer;
